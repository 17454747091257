<template>
  <div class="ledgerDetail">
    <el-dialog
      title="分账明细"
      width="600px"
      :close-on-click-modal="true"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <div class="log-table">
        <el-table
          :data="tableData"
          header-cell-class-name="table_header"
          row-key="id"
          border
          max-height="250"
        >
          <el-table-column
            prop="current_stage"
            label="期数"
            align="center"
          />
          <el-table-column
            prop="principal"
            label="本金"
            align="center"
          />
          <el-table-column v-if="hasInterest" prop="business_interest" label="商家管理费" align="center" />
          <el-table-column v-if="hasBusinessPay"
            prop="business_payer_interest"
            label="商家贴息"
            align="center"
          />
          <el-table-column v-if="hasRefund" prop="refund" label="退款手续费" align="center" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ledgerStageList } from "@/api/bill.js";
export default {
  name: "ledgerDetail",

  data() {
    return {
      tableData: [],
      searchForm: {
        page: 1,
        per_page: 10000,
      },
      loading: false,
      dialogFormVisible: false,

      hasInterest: false,
      hasBusinessPay: false,
      hasRefund: false,
    };
  },

  mounted() {
  },

  methods: {
    async isShow(ledgerOrderId) {
      this.dialogFormVisible = true;
      this.initData(ledgerOrderId)
    },
    async initData(ledgerOrderId){
      this.hasInterest    = false
      this.hasBusinessPay = false
      this.hasRefund      = false
      this.tableData = []

      this.searchForm.ledgerOrderId = ledgerOrderId
      const {code,data} = await ledgerStageList(this.searchForm)
      if(code == 200) {
        this.hasInterest    = data.list.some(el=>Number(el.business_interest)!=0)
        this.hasBusinessPay = data.list.some(el=>Number(el.business_payer_interest)!=0)
        this.hasRefund      = data.list.some(el=>Number(el.refund)!=0)
        this.tableData = data.list
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.searchForm = {
        page: 1,
        per_page: 10000,
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.ledgerDetail {
  /deep/ .customW {
    border-radius: 10px !important;
    .el-dialog__header {
      border-bottom: 1px solid #e8eaec;
      .el-dialog__title {
        font-size: 14px;
        color: #17233d;
        font-weight: 700;
      }
    }
    .el-dialog__footer {
      border-top: 1px solid #e8eaec;
    }
  }
  .log-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>