<template>
  <div class="repaymentList">
    <div>
      <span v-if="myBrand" class="mr_20">{{ myBrand.name }}</span>
      <span v-else-if="myComp" class="mr_20">{{ myComp.name }}</span>
      <span v-if="!myBrand && !myComp && myShop" class="mr_20">{{
        myShop.name
      }}</span>
    </div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div>
          <el-row :gutter="20" style="flex: 1">
            <el-col :span="5">
              <el-form-item label="商家" v-if="myBrand || myComp">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.businessId"
                  :remote-method="businessRemoteMethod"
                  :loading="businessLoading"
                  placeholder="选择商家"
                  clearable
                  filterable
                  remote
                  @focus="businessRemoteMethod()"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in businessList"
                    :key="item.id"
                    :label="item.full_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="状态">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.divideStatus"
                  clearable
                  placeholder="请选择"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in divideStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="还款时间">
                <el-date-picker
                  style="width: 100%"
                  v-model="repayment_Time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="setDateRange"
                  @change="serchData"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="分账时间">
                <el-date-picker
                  style="width: 100%"
                  v-model="divide_Time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="setDateRange"
                  @change="serchData"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input
                  v-model="searchForm.billNo"
                  placeholder="请输入订单号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学员名称">
                <el-input
                  v-model="searchForm.username"
                  placeholder="请输入学员名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="还款类型">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.payment_mode"
                  clearable
                  placeholder="请选择"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in payModeStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="ml_20">
                <el-button size="small" @click="onExport">导出</el-button>
                <el-button type="primary" size="small" @click="serchData"
                  >搜索</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="bill-table">
      <base-table
        :columns="repayment"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="getRepaymentList"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          width="150px"
          slot="bill_no"
          label="账单编号"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="checkDetail(scope.row)"
              >{{ scope.row.bill_no }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          slot="stage_count"
          label="期数"
          align="center"
          :formatter="stageCountFormatter"
        ></el-table-column>
        <el-table-column slot="stage_type" label="分期类型" align="center">
          <template slot-scope="scope">
            {{ stageTypeFormatter(scope.row) }}
            <template v-if="scope.row.stage_type == 1">
              {{ scope.row.stage_count }}期
            </template>
            <template v-if="scope.row.stage_type == 2">
              {{ scope.row.x_stage_count }}+{{ scope.row.y_stage_count }}期
            </template>
            <!-- {{stageTypeFormatter(scope.row)}} {{scope.row.business_full_name}} -->
          </template>
        </el-table-column>
        <el-table-column
          width="80px"
          slot="divide_status"
          label="账单状态"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.divide_status == 2"
              type="text"
              size="small"
              @click="detailEnquiry(scope.row)"
              >{{ divideStatusFormatter(scope.row) }}</el-button
            >
            <template v-else>
              {{ divideStatusFormatter(scope.row) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column slot="remark" label="备注" align="center">
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>
        <el-table-column slot="pay_mode" label="还款类型" align="center" :formatter="refundTypeFormatter">

        </el-table-column>
        <!-- <el-table-column
          slot="operation"
          label="操作"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="checkDetail(scope.row)">账单详情</el-button>
            <el-button type="text" size="small" @click="detailEnquiry(scope.row)">分账明细</el-button>
          </template>
        </el-table-column> -->
      </base-table>
    </div>
    <ledger-detail ref="LedgerDetail" />
  </div>
</template>

<script>
import { repaymentList } from "@/api/bill.js";
import { suggestBusinessList } from "@/api/business.js";
import { getMyBrand, getMyComp, getMyShop } from "@/utils/index.js";
import columns from "./columns";
import LedgerDetail from "./components/ledger-detail.vue";
import config from "@/utils/config.js";

export default {
  name: "repaymentList",
  components: { LedgerDetail },
  data() {
    return {
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),

      ...columns,
      searchForm: {
        page: 1,
        per_page: 10,
        divideStatus: -1,
      },
      loading: false,
      total: 0,
      tableData: [],
      payModeStatusList:[
        {
          value: 0,
          label: "当期支付"
        },
        {
          value: 1,
          label: "全部结清"
        },
        {
          value: 2,
          label: "部分结清"
        },
        {
          value: 3,
          label: "退课费结算"
        }
      ],
      businessLoading: false,
      businessList: [],
      baseBusinessList: [],

      divideStatusList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "待分账",
        },
        {
          value: 1,
          label: "分账中",
        },
        {
          value: 2,
          label: "已分账",
        },
        {
          value: 3,
          label: "分账失败",
        },
      ],
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      repayment_Time: [],
      divide_Time: [],
    };
  },

  async mounted() {
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness();
  },

  methods: {
    serchData() {
      if (this.repayment_Time && this.repayment_Time.length == 2) {
        this.searchForm.repaymentStartTime =
          this.repayment_Time[0];
        this.searchForm.repaymentEndTime =
          this.repayment_Time[1];
      } else {
        this.searchForm.repaymentStartTime = "";
        this.searchForm.repaymentEndTime = "";
        this.repayment_Time=[]
      }
      if (this.divide_Time && this.divide_Time.length == 2) {
        this.searchForm.divideStartTime =
          this.divide_Time[0];
        this.searchForm.divideEndTime = this.divide_Time[1];
      } else {
        this.searchForm.divideStartTime = "";
        this.searchForm.divideEndTime = "";
        this.divide_Time=[]

      }

      this.searchForm.page = 1;
      this.searchForm.per_page = 10;
      this.initData();
    },
    async initData() {
      this.getRepaymentList();
    },
    async getRepaymentList() {
      this.searchForm.seniorId = "";
      this.searchForm.entityId = "";
      if (!this.myBrand && !this.myComp && this.myShop) {
        this.searchForm.businessId = this.myShop.id;
      }
      if (!this.searchForm.businessId) {
        if (this.myBrand) {
          this.searchForm.seniorId = this.myBrand.id;
        } else if (this.myComp) {
          this.searchForm.entityId = this.myComp.id;
        }
      }
      this.loading = true;
      const { code, data } = await repaymentList(this.searchForm);
      this.loading = false;
      if (code == 200) {
        this.tableData = data.list.map((item) => {
          if ((item.remark || "").length > 20) {
            item.remark = item.remark.substring(0, 18) + "...";
          }
          return item;
        });
        this.total = data.totalCount;
      }
    },
    detailEnquiry(row) {
      console.log("分账明细", row);
      this.$refs.LedgerDetail.isShow(row.ledger_order_id);
    },
    checkDetail(row) {
      const routeData = this.$router.resolve({
        path: "/bill/billInfo",
        query: {
          billNo: row.bill_no,
        },
      });
      window.open(routeData.href, "_blank");
    },

    onExport() {
      if (
        !this.searchForm.repaymentStartTime &&
        !this.searchForm.divideStartTime
      ) {
        this.$message.warning("请至少选择一项时间");
        return;
      }
      if (this.repayment_Time.length == 2) {
        if (
          new Date(this.repayment_Time[1]).getTime() -
            new Date(this.repayment_Time[0]).getTime() >
          31 * 24 * 3600 * 1000
        ) {
          this.$message.warning("时间跨度不得超过一个月");
          return;
        }
      }
      if (this.divide_Time.length == 2) {
        if (
          new Date(this.divide_Time[1]).getTime() -
            new Date(this.divide_Time[0]).getTime() >
          31 * 24 * 3600 * 1000
        ) {
          this.$message.warning("时间跨度不得超过一个月");
          return;
        }
      }
      console.log("businessId", this.searchForm.businessId);
      console.log("divideStatus", this.searchForm.divideStatus);
      console.log("还款时间repaymentStartTime", this.searchForm.repaymentStartTime);
      console.log("还款时间repaymentEndTime", this.searchForm.repaymentEndTime);
      console.log("divideStartTime", this.searchForm.divideStartTime);
      console.log("divideEndTime", this.searchForm.divideEndTime);

      const url = `${
        config.BASE_URL
      }/export/repaymentList?token=${localStorage.getItem(
        "__token__"
      )}&businessId=${this.searchForm.businessId || ""}&divideStatus=${
        this.searchForm.divideStatus
      }&repaymentStartTime=${
        this.searchForm.repaymentStartTime || ""
      }&repaymentEndTime=${
        this.searchForm.repaymentEndTime || ""
      }&divideStartTime=${
        this.searchForm.divideStartTime || ""
      }&divideEndTime=${
        this.searchForm.divideEndTime || ""
      }&payment_mode=${this.searchForm.payment_mode}`;
      window.open(url, "_blank");
    },

    divideStatusFormatter(row) {
      switch (row.divide_status) {
        case 0:
          return "待分账";
        case 1:
          return "分帐中";
        case 2:
          return "已分账";
        case 3:
          return "分账失败";
      }
    },
    stageTypeFormatter(row) {
      switch (row.stage_type) {
        case 1:
          return "等额本金";
        case 2:
          return "组合还款";
      }
    },
    stageCountFormatter(row) {
      if (row.payment_mode == 0) {
        return (row.total_stage_count - row.remain_stage_count) + " / " + row.total_stage_count
      } else if(row.payment_mode == 1) {
        return row.total_stage_count + " / " + row.total_stage_count
      } else if(row.payment_mode == 2) {
        return 0
      } else {
        return ""
      }
    },
    refundTypeFormatter(row) {
      switch (row.payment_mode) {
        case 0:
          return "当期支付";
        case 1:
          return "全部结清";
        case 2:
          return "部分结清";
        case 3:
          return "退课费结算";
      }
    },

    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>