export const allBillList = [
  {
    label: '账单编号',
    prop: 'bill_no',
    width: 200,
    align: 'center',
  },
  {
    label: '用户姓名',
    prop: 'username',
    align: 'center',
    width: 80,
  },
  {
    label: '年龄',
    prop: 'age',
    align: 'center',
    width: 80,
  },
  {
    label: '手机号',
    prop: 'mobile',
    align: 'center',
    width: 110,
  },
  {
    label: '身份证号',
    prop: 'id_card_no',
    align: 'center',
    width: 150,
  },
  // {
  //   label: '主体名称',
  //   prop: 'entity_name',
  //   align: 'center',
  //   width: 150,
  // },
  {
    label: '商家',
    slot: 'business_name',
  },
  {
    label: '产品名称',
    prop: 'product_name',
    align: 'center',
    width: 150,
  },
  {
    label: '分期类型',
    slot: 'stage_type',
  },
  {
    label: '申请金额',
    prop: 'apply_amount',
    align: 'center',
    width: 80,
  },
  {
    label: '放款金额',
    prop: 'reality_amount',
    align: 'center',
    width: 80,
  },
  {
    label: '账单状态',
    slot: 'bill_status',
    width: 80,
  },
  {
    label: '有身份证照片',
    slot: 'is_id_card_img',
  },
  {
    label: '绑定银行卡',
    slot: 'is_bind_card',
  },
  {
    label: '是否还款',
    slot: 'isRepayment',
  },
  {
    label: '申请时间',
    prop: 'approve_time',
    align: 'center',
    width: 150,
  },
  {
    label: '退费时间',
    prop: 'refund_time',
    align: 'center',
    width: 150,
  },
  {
    label: '完结时间',
    prop: 'settle_time',
    align: 'center',
    width: 150,
  },
  {
    label: '备注',
    slot: 'remark',
  },
  {
    label: '操作',
    slot: 'operation',
  },
]

export const costInfo = [
  {
    label: '当前分期',
    prop: 'current_stage',
    align: 'center',
  },
  {
    label: '还款时间',
    prop: 'repayment_date',
    align: 'center',
  },
  {
    label: '分期类型',
    slot: 'combine_stage_type',
  },
  {
    label: '总额',
    slot: 'repayment_total',
  },
  {
    label: '分期本金',
    prop: 'repayment_principal',
    align: 'center',
  },
  {
    label: '剩余未还本金',
    prop: 'remain_repayment_principal',
    align: 'center',
  },
  {
    label: '是否逾期',
    slot: 'is_overdue',
  },
  {
    label: '逾期天数',
    prop: 'overdue_day_count',
    align: 'center'
  },
  {
    label: '逾期滞纳金',
    prop: 'overdue_late_fee',
    align: 'center',
  },
  {
    label: '逾期罚金',
    prop: 'overdue_fine',
    align: 'center',
  },
  {
    label: '是否延期',
    slot: 'is_delay_stage',
  },
  {
    label: '支付状态',
    slot: 'payment_status',
  },
  {
    label: '提前还款手续费',
    prop: 'early_repayment_fees',
    align: 'center',
  },
]

export const repayment = [
  {
    label: '账单编号',
    slot: 'bill_no',
    align: 'center',
  },
  {
    label: '用户姓名',
    prop: 'username',
    align: 'center',
  },
  {
    label: '手机号',
    prop: 'mobile',
    align: 'center',
  },
  {
    label: '商家',
    prop: 'business_name',
    align: 'center',
  },
  {
    label: '购买产品',
    prop: 'product_name',
    align: 'center',
  },
  {
    label: '分期类型',
    slot: 'stage_type'
  },
  {
    label: '期数',
    slot: 'stage_count'
  },
  {
    label: '还款（元）',
    prop: 'repayment_principal',
    align: 'center',
    width: 120,
  },
  {
    label: '收入（元）',
    prop: 'ledger_amount',
    align: 'center',
    width: 100,
  },
  {
    label: '申请时间',
    prop: 'approve_time',
    align: 'center',
  },
  {
    label: '还款时间',
    prop: 'repayment_time',
    align: 'center',
  },
  {
    label: '分账时间',
    prop: 'divide_time',
    align: 'center',
  },
  {
    label: '备注',
    slot: 'remark',
  },
  {
    label: '还款类型',
    slot: 'pay_mode'
  },
  {
    label: '分账状态',
    slot: 'divide_status'
  },
  
]

export const stageBillList = [
  {
    label: '账单编号',
    slot: 'bill_no',
  },
  {
    label: '用户姓名',
    prop: 'real_name',
    align: 'center',
    width: 80,
  },
  {
    label: '手机号',
    prop: 'mobile',
    align: 'center',
    width: 110,
  },
  {
    label: '期数',
    slot: 'current_stage',
  },
  {
    label: '应还款日期',
    prop: 'repayment_date',
    align: 'center',
    width: 150,
  },
  {
    label: '还款日期',
    prop: 'payment_date',
    align: 'center',
    width: 150,
  },
  {
    label: '应还本金',
    prop: 'should_repayment_principal',
    align: 'center',
  },
  {
    label: '状态',
    slot: 'bill_stage_status',
  },
  {
    label: '逾期天数',
    prop: 'overdue_day_count',
    align: 'center',
  },
 
  {
    label: '实还本金',
    prop: 'repayment_principal',
    align: 'center',
  },
  {
    label: '商家管理费',
    slot: 'repayment_business_interest',
  },
  {
    label: '商家可得',
    prop: 'repayment_business_amount',
    align: 'center',
  },
  {
    label: '还款模式',
    slot: 'payment_mode',
  },
  {
    label: '备注',
    slot: 'remark',
  },
]
export default {
  allBillList,
  costInfo,
  repayment,
  stageBillList
}
