import request from '@/utils/request'

// 帐单列表
export function billList(params) {
  return request.post('/api/billList', params)
}

// 帐单详情
export function billDetail(params) {
  return request.post('/api/billDetail', params)
}

// 帐单审核
export function billApprove(params) {
  return request.post('/api/billApprove', params)
}

//还款分账列表
export function repaymentList(params) {
  return request.post('api/repaymentList', params)
}

//分账明细列表
export function ledgerStageList(params) {
  return request.post('api/ledgerStageList', params)
}

//申请账单退费
export function applyRefund(params) {
  return request.post('api/applyRefund', params)
}

//取消账单退费
export function cancelRefund(params) {
  return request.post('api/cancelRefund', params)
}

//账单备注
export function billRemark(params) {
  return request.post('api/billRemark', params)
}

//分期账单列表
export function billStageRepaymentList(params) {
  return request.post('api/billStageRepaymentList', params)
}

//账单延期
export function applyBillDelay(params) {
  return request.post('api/applyBillDelay', params)
}

// 修改还款日期
export function billDateChange(params) {
  return request.post('/api/changeBill', params)
}

// 获取修改日期权限
export function getBillDateState(params) {
  return request.post('/api/changeBillStatus', params)
}